<template>
  <div v-if="isInAppBrowser" class="d-flex flex-column align-items-center w-100 home-view" id="home">
    <div class="d-flex flex-column align-items-center">
      <div class="logo-container my-4">
        <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/logo-los-50" alt="" class="los-50-logo" width="230" height="150">
      </div>
      <p class="main-p font-weight-bold w-85 mb-3 no-hyphen">
        Esta aplicación debe abrirse en un navegador externo para funcionar correctamente.
      </p>
      <p class="w-85 mb-3 text-center no-hyphen" style="color: white">
        Por favor, siga estos pasos:
      </p>
      <ol class="w-85 mb-3 no-hyphen" style="color: white">
        <li>Toque el ícono de opciones (generalmente tres puntos o líneas) en la esquina de su navegador actual.</li>
        <li>Busque y seleccione la opción 'Abrir en navegador' o 'Abrir en [nombre de su navegador]'.</li>
        <li>Si no ve esta opción, copie la URL y péguela en su navegador preferido.</li>
      </ol>
    </div>
  </div>
  <div v-else-if="contentPage" class="d-flex flex-column align-items-center w-100 home-view" id="home">

    <div class="d-flex flex-column align-items-center">
      <div class="logo-container my-4">
        <img :src="contentPage.instance.header.content.values.image.value.public_path" alt="" class="los-50-logo" width="230" height="150">
      </div>

      <span v-if="eventStatus == 'registration_end'">
          <p class="main-p font-weight-bold w-85 mb-3" v-html="replaceVariables(contentPage.instance['thanks-message'].content.values.title.value)"></p>
          </span>
      <p v-if="eventStatus == 'registration_end'" class="main-p font-weight-bold w-85 mb-3" v-html="replaceVariables(contentPage.instance['votation-instructions'].content.values.description.value)"></p>

      <p v-if="eventStatus == 'active'" class="main-p font-weight-bold w-85 mb-3" v-html="contentPage.instance.header.content.values.title.value"></p>
      <p v-else class="main-p w-85 mb-3" v-html="contentPage.instance['home-closed'].content.values.title.value"></p>

      <button v-if="eventStatus == 'active'" class="main-btn mb-3" @click="$router.push(contentPage.instance.register_button.content.values.url.value)">
        <span v-html="contentPage.instance.register_button.content.values.text.value"/>
      </button>

      <button class="secondary-btn mb-3" @click="$router.push(contentPage.instance.login_button.content.values.url.value)">
        <span v-html="contentPage.instance.login_button.content.values.text.value"/>
      </button>

      <div class="o mbz-4 mb-3"></div>

      <button @click.prevent="openAuthWindow(googleAuthUrl)" class="secondary-btn w-100 mb-3 d-flex justify-content-center align-items-center" style="gap: 10px">
        <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-google-login" alt="google-icon">
        <span>Continúa con Google</span>
      </button>

      <button @click.prevent="openAuthWindow(facebookAuthUrl)" class="secondary-btn w-100 mb-3 d-flex justify-content-center align-items-center" style="gap: 10px">
        <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/icon-fb-login" alt="facebook-icon">
        <span>Continúa con Facebook</span>
      </button>
    </div>
  </div>
  <div v-else class="loader">
    <Loader></Loader>
  </div>
</template>

<script>
import axios from 'axios';
import Oauth from '@helpers/oauth.js';
import Loader from './Loader.vue';
import { jwtDecode } from "jwt-decode";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, remove } from 'firebase/database';
import {random} from "lodash";

export default {
  mixins: [Oauth],
  data() {
    return {
      contentPage: null,
      mintsContactToken: null,
      googleAuthUrl: '',
      facebookAuthUrl: '',
      identifier: null,
      accessToken: null,
      idToken: null,
      contactTokenCali: null,
      isInAppBrowser: false,
    }
  },
  props: {
    eventStatus: String,
    appEnv: String,
  },
  computed: {
    csrfToken() {
      return document.querySelector('meta[name="csrf-token"]').content
    }
  },
  components: { Loader },
  mounted() {
    this.detectInAppBrowser();
    const firebaseConfig = {
      apiKey: "AIzaSyAGFPBzB2ncaQeIVtNB-G6lM163pVm8fMI",
      authDomain: "shared-impl.firebaseapp.com",
      databaseURL: "https://shared-impl-default-rtdb.firebaseio.com",
      projectId: "shared-impl",
      storageBucket: "shared-impl.appspot.com",
      messagingSenderId: "293450046198",
      appId: "1:293450046198:web:66201bb20e8a8f367f6ad7"
    };

    this.identifier = this.randomString(16);

    const firebaseApp = initializeApp(firebaseConfig);
    const database = getDatabase(firebaseApp)
    console.log("database", database);

    const oauthData = ref(database, 'oauth/' + this.identifier);
    onValue(oauthData, (snapshot) => {
      const data = snapshot.val();
      if(data){
        this.accessToken = data.access_token;
        this.idToken = data.id_token;
        remove(oauthData)
        this.handleAuthComplete()
      }
    });

    this.getSupportData();
    this.setupAuthUrls();
  },
  methods: {

    randomString(length) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },
    detectInAppBrowser() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isFacebookApp = /FBAN|FBAV/i.test(userAgent);
      const isInstagramApp = /Instagram/i.test(userAgent);
      const isTwitterApp = /Twitter/i.test(userAgent);

      this.isInAppBrowser = isFacebookApp || isInstagramApp || isTwitterApp;
    },

    openAuthWindow(url) {
      const width = 600;
      const height = 600;
      const left = (screen.width / 2) - (width / 2);
      const top = (screen.height / 2) - (height / 2);
      const authWindow = window.open(url, 'auth', `width=${width},height=${height},top=${top},left=${left}`);
      authWindow.localStorage.setItem('identifier', this.identifier)
    },

    handleAuthComplete() {
      const access_token = this.accessToken;
      const id_token = this.idToken;
      if (access_token && id_token) {
        this.setTokens({access_token, id_token});
      }
    },
    setTokens(tokens) {
      localStorage.setItem('access_token', tokens.access_token);
      localStorage.setItem('id_token', tokens.id_token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${tokens.access_token}`;
      if (tokens.id_token && tokens.access_token) {
        const decoded = jwtDecode(tokens.id_token);
        this.setSessionProcess(tokens.id_token, tokens.access_token, decoded)
      }
    },
    setSessionProcess(idToken, accessToken, userInfo) {
      axios.post('/api/v1/login/facebook/set-login', {
        id_token: idToken,
        access_token: accessToken,
        user_info: userInfo
      }).then(response => {
        this.idToken = response.data.id;
        this.mintsContactToken = response.data.token;
        this.contactTokenCali = response.data.contact_token
        this.getSessionStorage();
      })
    },
    setupAuthUrls() {
      const baseUrl = 'https://auth.los50.app/oauth2/authorize';
      const commonParams = new URLSearchParams({
        client_id: '641cc2h6lh5hbjbmq29kjg05es',
        response_type: 'token',
        scope: 'email openid profile',
        redirect_uri: this.appEnv === 'development' ? 'https://followers-los50-development-j7mqwtim2q-uc.a.run.app/auth/callback' : 'https://portal.los50.app/auth/callback'
      });

      this.googleAuthUrl = `${baseUrl}?${commonParams.toString()}&identity_provider=Google`;
      this.facebookAuthUrl = `${baseUrl}?${commonParams.toString()}&identity_provider=Facebook`;
    },
    getSupportData() {
      axios.get('api/v1/get-content-page/home').then(response => {
        this.contentPage = response.data.data
      }).catch((error) => {
        console.log(error);
      })
    },
    replaceVariables(text) {
      text = text.replace(/\{\{open-span\}\}/g, '<span class="font-weight-bold">');
      text = text.replace(/\{\{close-span\}\}/g, '</span>');
      text = text.replace(/\{\{br\}\}/g, '<br>');
      let givenName = this.me?.given_name || '';

      return text;
    },
    getSessionStorage() {
      localStorage.setItem('mints_contact_token', this.mintsContactToken);
      localStorage.setItem('mints_contact_id', this.idToken);
      localStorage.setItem('contact_token_cali', this.contactTokenCali);
      axios.defaults.headers.common['custom-auth'] = this.mintsContactToken;
      let id = this.idToken
      let localStorageKey = 'followers.contact.' + id;
      this.getData(localStorageKey, id);
    },
    getData(localStorageKey, id) {
      axios.post('api/v1/get-session', {id: id}).then(response => {
        if (response.data) {
          let contactData = response.data;
          this.updateLocalStorage(localStorageKey, contactData);
        }
      });
    },
    updateLocalStorage(localStorageKey, contact) {
      let me = {
        'id': contact.id,
        'mints_contact_id': contact.token_id,
        'given_name': contact.given_name,
        'last_name': contact.last_name,
        'phone': contact.phone || null,
        'email': contact.email || null,
        'zip_code': contact.zip_code_2024 || null,
        'time_zone': contact.time_zone_2024 || null,
        'confirm_age': contact.confirm_age_2024 || null,
        'privacy_policy': contact.privacy_policy_2024 || null,
        'terms_and_conditions_accepted': contact.terms_and_conditions_accepted_2024 || null,
        'send_promotions_and_news': contact.send_promotions_and_news_2024 || null,
        'full_name': contact.full_name,
        'display_name': contact.display_name,
        'app_status': contact.app_status,
        'device': contact.device,
      }
      window.localStorage.setItem(localStorageKey, JSON.stringify(me));
      this.$router.push({path: '/lobby'})
    },
  }
};
</script>

<style>
.o {
  color: white;
}

.inline-text-with-icon {
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 4px;
  text-align: center;
  line-height: 1.5;
}

.inline-icon {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  flex-shrink: 0;
}

@media (max-width: 480px) {
  .inline-text-with-icon {
    font-size: 0.9em;
    gap: 2px;
  }

  .inline-icon {
    width: 12px;
    height: 12px;
  }

  .no-hyphen {
    hyphens: none;
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: none;
    -ms-hyphens: none;
  }
}
</style>